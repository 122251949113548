import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";
import Login from "../views/Login.vue";
import ErrorPage from "../views/Error.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/error_page",
    name: "ErrorPage",
    component: ErrorPage,
    meta: {
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isLogin = store.state.isLogin;
  const routeMap = JSON.parse(JSON.stringify(store.state.routeMap));
  if (isLogin) {
    if (routeMap.length == 0) {
      await store.dispatch("getDynamicRouteOfUser");
      next({ ...to, replace: true });
    } else {
      if (router.getRoutes().length == 2) {
        store.dispatch("updateUserRoute", routeMap);
        next({ ...to, replace: true });
      } else {
        if (to.name == "Login") {
          next({ name: "Home" });
        } else {
          next();
        }
      }
    }
  } else {
    if (
      to.matched.some((record) => record.meta.requiresAuth) ||
      to.matched.length == 0
    ) {
      next({ name: "Login" });
    } else {
      next();
    }
  }
});
export default router;
