<template>
  <div class="not_found">
    <div class="container">
      <div class="content centered">
        <img
          style="width: 500px"
          src="https://social.webestica.com/htdocs_error/something-lost.png"
        />
        <h1>啊偶！ 你访问的页面跑丢了</h1>
        <p style="font-size: 22px" class="sub-header text-block-narrow">
          看看其他产品
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "not_found",
};
</script>

<style scoped>
.content {
  padding-top: 10%;
  position: relative;
}
.centered {
  text-align: center;
}
</style>
