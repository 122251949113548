<template>
  <div class="login-simple login-box">
    <!-- /.login-logo -->
    <div class="card card-outline card-primary">
      <div class="card-header text-center">
        <p class="h1">
          <b>{{ title }}</b>
        </p>
        <img :src="logo ? ($config.url + logo) : '/assets/img/logo.png'" class="img-logo" alt="" />
      </div>
      <div class="card-body">
        <p class="login-box-msg">{{ sub_title }}</p>
        <form>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="username" placeholder="账号" />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-user"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input type="password" class="form-control" v-model="password" placeholder="密码" />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="code" placeholder="请输入验证码" />
            <div class="input-group-append">
              <div class="input-group-text" style="padding: 0">
                <span class="fas">
                  <img style="height: 36px" :src="
                    captcha_src || '/assets/img/loadding/circle-point.gif'
                  " @click="change" alt="" />
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- <div class="col-8">
              <div class="icheck-primary">
                <input type="checkbox" id="remember" />
                <label for="remember"> Remember Me </label>
              </div>
            </div> -->
            <!-- /.col -->
            <!-- <div class="col-4">
              <button type="submit" class="btn btn-primary btn-block">
                Sign In
              </button>
            </div> -->
            <!-- /.col -->
          </div>


          <div class="social-auth-links text-center mt-2 mb-3">
            <button class="btn btn-block btn-primary" @click.prevent="submit" :disabled="disabled">
              <i :class="'fas fa-' + submit_ico + ' mr-2'"></i> 登录
            </button>
            <!-- <a href="#" class="btn btn-block btn-danger">
            <i class="fab fa-google-plus mr-2"></i> Sign in using Google+
          </a> -->
          </div>
        </form>
        <!-- /.social-auth-links -->

        <!-- <p class="mb-1">
          <a href="forgot-password.html">I forgot my password</a>
        </p>
        <p class="mb-0">
          <a href="register.html" class="text-center"
            >Register a new membership</a
          >
        </p> -->
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->
  </div>
</template>

<script>
import { login } from "@/requests/api";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "LoginSimple",
  props: {
    msg: String,
    title: {
      type: String,
      default: "sunny_admin",
    },
    sub_title: {
      type: String,
      default: "通用管理平台",
    },
    logo: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["isLogin"]),
  },
  data() {
    return {
      username: "",
      password: "",
      code: "",
      submit_ico: "lock",
      disabled: true,
      captcha_src: '',
      captcha: this.$config.url + this.$config.api + 'captcha'
    };
  },
  mounted() {
    if (this.isLogin) {
      this.$router.replace({
        path: "/",
      });
    }
  },
  updated() {
    if (!this.captcha_src) {
      this.change();
    }
    this.validate();
  },
  methods: {
    ...mapMutations(["login"]),
    ...mapActions(["getDynamicRouteOfUser"]),
    change() {
      this.captcha_src = this.captcha + "?id=" + Math.random();
    },
    validate() {
      let userValidate = new RegExp("^[A-Za-z0-9]{5,20}$");
      let passwordValidate = new RegExp("^[A-Za-z0-9\u4e00-\u9fa5]{8,25}$");
      let codeValidate = new RegExp("^[A-Za-z0-9]{4,8}$");
      if (
        !userValidate.test(this.username) ||
        !passwordValidate.test(this.password) ||
        !codeValidate.test(this.code)
      ) {
        this.submit_ico = "lock";
        this.disabled = true;
        return;
      }
      this.submit_ico = "paper-plane";
      this.disabled = false;
    },
    submit() {
      login({
        username: this.username,
        password: this.password,
        code: this.code,
      })
        .then((data) => {
          if (!data.code || data.code == -1) {
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "登录失败",
              text: data.msg,
              showConfirmButton: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 1500,
            });
            this.change();
            return;
          }
          // 登录成功 保存登录状态和用户数据
          this.login(data.data);
          this.getDynamicRouteOfUser().then(() => {
            this.$swal
              .fire({
                position: "top-center",
                icon: "success",
                title: "登录成功",
                // text: data.msg,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 1500,
              })
              .then(() => {
                this.$router.replace({
                  path: "/",
                });
              });
          })

        })
        .catch((err) => {
          this.change();
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.img-logo {
  max-height: 75px;
}
</style>
