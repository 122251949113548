<template>
  <div class="loading" v-show="msg.show">
    <div class="load-box">
      <img src="/assets/img/loadding/circle.gif" />
      <span>{{ msg.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "loading",
  props: {
    msg: Object,
  },
};
</script>

<style scoped>
.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}
.load-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.8px;
  font-size: 13px;
  color: white;
}
img {
  width: 60px;
  margin-bottom: 8px;
}
</style>
