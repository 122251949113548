import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import Swal from "vue-sweetalert2";
import "xe-utils";
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";

const config = require("@/config/config.js");
function useTable(app) {
  VXETable.setup({
    // size: "small",
    zIndex: 9999, // 层级
  });
  app.use(VXETable);
  app.config.globalProperties.$config = config;
  // 给 vue 实例挂载内部对象，例如：
  app.config.globalProperties.$XModal = VXETable.modal;
  // app.config.globalProperties.$XPrint = VXETable.print
  // app.config.globalProperties.$XSaveFile = VXETable.saveFile
  // app.config.globalProperties.$XReadFile = VXETable.readFile
}

createApp(App).use(store).use(router).use(Swal).use(useTable).mount("#app");
