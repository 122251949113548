<template>
  <div id="app">
    <router-view v-if="routerState" />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      routerState: true
    }
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  methods: {
    reload() {
      this.routerState = false;
      this.$nextTick(() => {
        this.routerState = true;
      })
    }
  }
}
</script>
<style scoped>
#app {
  width: 100vw;
  height: 100vh;
}
</style>
