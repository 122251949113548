import axios from "axios";
import router from "@/router";
import store from "@/store";
import qs from "qs";
import { host, proto, api } from "@/config/config";
import { load } from "@/components/loading/loading.js";
// 默认请求服务器
axios.defaults.baseURL = proto + "://" + host + api;

// 默认请求超时时间设置
axios.defaults.timeout = 5000;
axios.defaults.withCredentials = true;
// 默认的请求头设置
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

// 请求拦截
axios.interceptors.request.use(
  (config) => {
    if (router.currentRoute.value.path != "/error_page") {
      load.show();
    }
    const token = store.state.userInfo.token || "";
    token && (config.headers.Authorization = token);
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截

axios.interceptors.response.use(
  (response) => {
    if (router.currentRoute.value.path != "/error_page") {
      load.hide();
    }

    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (router.currentRoute.value.path != "/error_page") {
      load.hide();
    }
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          // 当服务器返回登录失效时，执行登录操作登录
          console.log(error.response.status);
          store.commit("loginOut");
          router.replace({
            path: "/login",
            query: {
              redirect: router.currentRoute.fullPath,
            },
          });
          break;
        default:
      }
      return Promise.reject(error.response);
    } else {
      console.log(error.response.status);
      if (router.currentRoute.value.path != "/error_page") {
        router.replace({
          path: "/error_page",
          query: {
            redirect: router.currentRoute.fullPath,
          },
        });
      }
    }
  }
);

// 封装常见请求

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        paramsSerializer(params) {
          return qs.stringify(params);
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function post(url, params, options) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, options)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
