import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import NotFound from "@/views/NotFound";
import myrouter from "@/router";
import { getUserRouter } from "@/requests/api";

// handle views
const loadView = (viewPath) => {
  return () => import("@/views/" + viewPath);
};

// Handle routers
const filterRouter = (routers) => {
  return routers.filter((router) => {
    router.component = loadView(router.component_name);
    if (router.children && router.children.length) {
      router.children = filterRouter(router.children);
    }
    return true;
  });
};

export default createStore({
  state: {
    isLogin: false,
    userInfo: {},
    menuTree: [],
    siteInfo: [],
    routeMap: [],
  },
  mutations: {
    login(state, provider) {
      state.userInfo = provider;
      state.isLogin = true;
    },
    loginOut(state) {
      state.userInfo = {};
      state.routeMap = [];
      state.isLogin = false;
    },
    loadMenu(state, provider) {
      state.menuTree = provider;
    },
    setSiteInfo(state, provider) {
      state.siteInfo = provider;
    },
    setRouteMap(state, provider) {
      state.routeMap = provider;
    },
    setDynamicRouteMap(state, provider) {
      let routerMaps = filterRouter(provider);
      // 最后追加404路由
      routerMaps.push({
        path: "/:pathMatch(.*)*",
        component: NotFound,
      });
      // 追加路由
      // 这块是重点，如果直接使用addRoute是无效的
      routerMaps.forEach((item) => {
        myrouter.addRoute(item);
      });
    },
  },
  actions: {
    getDynamicRouteOfUser({ commit }) {
      return new Promise((resolve) => {
        getUserRouter().then((res) => {
          commit("setRouteMap", res);
          commit("setDynamicRouteMap", res);
          console.log(123);
          return resolve();
        });
      });
    },
    // 刷新重置路由
    updateUserRoute({ commit }, routerMap) {
      commit("setDynamicRouteMap", routerMap);
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      reducer(val) {
        return {
          // 只储存state中的assessmentData
          isLogin: val.isLogin,
          userInfo: val.userInfo,
          menuTree: val.menuTree,
          siteInfo: val.siteInfo,
          routeMap: val.routeMap,
        };
      },
    }),
  ],
});
