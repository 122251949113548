<template>
  <div class="login hold-transition login-page" :style="plat_info.bg_style">
    <LoginSimple :title="plat_info.title" :sub_title="plat_info.sub_title" :logo="plat_info.logo"
      :captcha="plat_info.captcha" />
  </div>
</template>

<script>
import LoginSimple from "@/components/login/LoginSimple.vue";
import { plat_info } from "@/requests/api.js";
import { mapMutations } from 'vuex'
export default {
  name: "Login",
  components: {
    LoginSimple,
  },
  beforeCreate() {
    plat_info()
      .then((res) => {
        this.plat_info = res.data;
        console.log(res.data)
        this.setSiteInfo(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      plat_info: {
        logo: "",
        title: "",
        sub_title: "",
        bg_style: "",
        captcha: "",
      },
    };
  },
  methods: {
    ...mapMutations(['setSiteInfo'])
  }
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100%;
}
</style>
