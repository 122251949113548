import { get, post } from "./http";

export const plat_info = (p) => get("plat_info", p);
export const login = (p) => post("login", p);
export const loginout = (p) => post("/v1/logout", p);
export const getUserRouter = (p) => get("user/router/v1", p);
export const getSlideMenus = (p) => post("v1/getslidemenus", p);
export const getMenusList = (p) => post("v1/getmenuslist", p);
export const getAllMenu = (p) => get("v1/getallmenu", p);
export const menuAdd = (p) => post("v1/menu/add", p);
export const menuDel = (p) => post("v1/menu/del", p);
export const menuUpdate = (p) => post("v1/menu/update", p);
export const routeList = (p) => post("v1/route/list", p);
export const routeAdd = (p) => post("v1/route/add", p);
export const getRoleList = (p) => get("v1/roles/list", p);
export const createGroup = (p) => post("v1/role/add", p);
export const createManager = (p) => post("v1/manager/create", p);
export const groupList = (p) => get("v1/role/list", p);
export const groupAll = (p) => get("v1/role/getall", p);
export const managerList = (p) => get("v1/manager/list", p);
export const routeUpdate = (p) => post("v1/route/update", p);
export const routeDelete = (p) => post("v1/route/del", p);
export const logList = (p) => post("v1/log/list", p);

export const workerList = (p) => post("v1/worker/list", p);

export const orderList = (p) => post("v1/order/list", p);

export const ordersList = (p) => get("v1/orders/list", p);
export const getAllSotre = (p) => get("v1/store/all", p);
export const getAllWorker = (p) => get("v1/worker/all", p);
export const ordersAdd = (p) => post("v1/order/add", p);
export const ordersDel = (p) => post("v1/orders/del", p);
export const ordersUpdate = (p) => post("v1/orders/update", p);
export const ordersAll = (p) => get("v1/orders/all", p);
// 用户接口
export const userList = (p) => post("v1/user/list", p);
export const userUpdate = (p) => post("v1/user/update", p);
// 服务接口
export const serviceList = (p) => post("v1/service/list", p);
export const serviceAdd = (p) => post("v1/service/add", p);
export const serviceUpdate = (p) => post("v1/service/update", p);
export const serviceDel = (p) => post("v1/service/del", p);
// 商家接口
export const storeList = (p) => post("v1/store/list", p);
export const storeAdd = (p) => post("v1/store/add", p);
export const storeDel = (p) => post("v1/store/del", p);
export const storeUpdate = (p) => post("v1/store/update", p);
// 仓库订单接口
export const restoreOrder = (p) => post("v1/order/restore", p);
export const matterOrder = (p) => get("v1/orders/matter", p);
export const repositoryAdd = (p) => post("v1/repository/add", p);
export const repositoryUpdate = (p) => post("v1/repository/update", p);
export const repositoryList = (p) => get("v1/repository/list", p);
export const repositoryDel = (p) => post("v1/repository/del", p);
export const repositoryToStore = (p) => post("v1/repository/tostore", p);
export const updateGroup = (p) => post("v1/group/update", p);
export const repositoryImport = (p) =>
  post("v1/repository/import", p, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const saveBaseSet = (p) => post("v1/settings/base/save", p);
export const saveMiniBgSet = (p) => post("v1/settings/savebg", p);
export const getBaseBgSet = (p) => get("v1/settings/bg", p);
export const saveMiniAuthBgSet = (p) => post("v1/settings/saveauthbg", p);
export const getBaseAuthBgSet = (p) => get("v1/settings/authbg", p);
export const getBaseSet = (p) => get("v1/settings/saveauthbg", p);
export const getMapAk = (p) => get("v1/settings/position/ak", p);
export const savePositionSet = (p) => post("v1/settings/position/save", p);
export const getPositionSet = (p) => get("v1/settings/position", p);
export const getMsgAkSK = (p) => get("v1/settings/msg/aksk", p);
export const saveMsgSet = (p) => post("v1/settings/msg/save", p);
export const getMsgSet = (p) => get("v1/settings/msg", p);
export const getMiniBaseSet = (p) => get("v1/settings/minibase", p);
export const saveMiniBaseSet = (p) => post("v1/settings/minibase/save", p);
export const getMiniLbSet = (p) => get("v1/settings/minilb", p);
export const saveMiniLbSet = (p) => post("v1/settings/minilb/save", p);
// 客服订单接口
export const getKFOrder = (p) => get("v1/kefu/order", p);
export const saveKFOrder = (p) => post("v1/kefu/addorder", p);
export const updateKFOrder = (p) => post("v1/kefu/updateorder", p);
export const updateKFOrderStatus = (p) => post("v1/kefu/updateorderstatus", p);
export const kfToStore = (p) => post("v1/kefu/tostore", p);
export const getDoorStore = (p) => get("v1/kefu/doorstore", p);
export const kfOrderImport = (p) =>
  post("v1/kefu/import", p, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const getDashData = (p) => get("v1/dashdata", p);

export const getApisList = (p) => get("apis/list/v1", p);
export const createApi = (p) => post("apis/create/v1", p);
export const delApi = (p) => post("apis/del/v1", p);
export const getApiInfo = (p) => get("apis/info/v1", p);
export const getBusiness = (p) => get("v1/test/test", p);
